<template>
    <div class="flex justify-between">
        <StatusButton v-for="(count, status) in currentStatuses" :key="status" :active="selectedInvoiceType === status" @click="statusClicked(status)">
            <template #label>{{ capitalizeFirstLetter(status) }}</template>
            <template #count>{{ count }}</template>
        </StatusButton>
    </div>
</template>
<script>
import queryString from "query-string";
import Api from "../invoices.api.js";
export default {
    data() {
        return {
            selectedInvoiceType: "all",
            currentStatuses: [],
            invoiceTypes: {
                all: ["All", "Fresh", "Paid", "Partialy paid", "Overpaid", "Unpaid", "Underpaid"],
                reminder: ["All", "Fresh", "Paid", "Partialy paid", "Overpaid", "Unpaid", "Underpaid"],
                inkasso: ["All", "Fresh", "Paid", "Partialy paid", "Overpaid", "Unpaid", "Underpaid"],
                eInvoice: ["All", "Fresh", "Paid", "Partialy paid", "Overpaid", "Unpaid", "Underpaid"],
                autogiro: ["All", "Fresh", "Paid", "Unpaid"],
                creditNote: ["All", "Fresh", "Paid", "Partialy paid", "Overpaid", "Unpaid", "Underpaid"],
            },
        };
    },

    components: {
        StatusButton: () => import("./StatusButton.vue"),
    },

    created() {
        if (!this.isGlobalClientSelected) this.getAll();
    },

    methods: {
        async getAll() {
            const { search, currency, clientId, dateFrom, dateTo } = queryString.parse(window.location.search, { sort: false });
            this.currentStatuses = await Api.getCounts(search, currency, clientId, dateFrom, dateTo);
            const query = queryString.parse(window.location.search, { sort: false });
            this.selectedInvoiceType = query.status ? query.status : "all";
        },

        statusClicked(status) {
            this.selectedInvoiceType = status;
            const query = queryString.parse(window.location.search, { sort: false });
            query.pageNumber = 1;
            query.status = status;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("searchParametersChanged");
        },

        capitalizeFirstLetter(string) {
            const translation = {
                all: "Alla",
                paid: "Betalat",
                unpaid: "Obetald",
                overpaid: "Överbetald",
                underpaid: "Underbetald",
            };
            return translation[string];
        },
    },

    computed: {
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>
